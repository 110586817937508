<template>
  <div 
      style='word-break: keep-all;'
    :style='backgroundImage'>
    <div class='flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:gap-y-0 gap-x-0 lg:gap-x-8 mb-2 lg:mb-4'>
      <div class='w-full flex flex-col justify-start gap-y-2 lg:gap-y-4'>
        <div>

          <div class='flex flex-col lg:flex-row justify-between items-start lg:items-center'>
            <div 
              class='flex flex-row justify-start items-center gap-x-4 font-bold' 
              :class='textSizeBasedOnLocation'>
              <h1 v-html='highlightedText(session.title)'></h1>
              <div v-if='showHeartButton && hasValidToken'
                class='rounded-md border border-transparent h-6 w-6 flex flex-row items-center hover:bg-gray-200 hover:border-gray-500'
                @click.stop='toggleHearted'>
                <component :is='whichHeartIcon' class='h-4'/>
              </div>
            </div>
            <div class='flex-shrink-0'>
              <div class='flex flex-row items-center justify-start lg:justify-end gap-x-2'>
                <div class='whitespace-nowrap text-sm lg:text-base uppercase'>
                  {{ sessionScheduledStartDate }}
                </div>
                <div class='whitespace-nowrap text-sm lg:text-base font-semibold border-r pr-2'>
                  {{ sessionScheduledStartTime }} - {{ sessionScheduledEndTime }}
                </div>
                <div class='whitespace-nowrap text-sm lg:text-base uppercase'>
                  {{ sessionLocationString }}
                </div>
              </div>
            </div>
          </div>

          <div class='flex flex-col lg:flex-row justify-start items-start lg:items-end gap-y-2 lg:gap-x-2'>
            <h2 v-if='session.subtitle'
              class='font-medium lg:text-xl text-gray-800'
              v-html='highlightedText(session.subtitle)'></h2>
            <div v-if='session.description'
              class='uppercase text-gray-500 mb-2 lg:mb-0 cursor-pointer hover:bg-gray-100 py-0.5 pl-1 pr-2 flex flex-row justify-start items-center border rounded'
              @click='toggleShowDetails'>
              <component :is='whichDetailsCarot' class='h-4 w-4 inline-block' />
              <span class='text-xs lg:text-sm font-medium'>
                {{ toggleDetailsText }}
              </span>
            </div>
          </div>
          <h3 v-if='session.description && showDetails'
            class='whitespace-pre-line text-base'>
            {{ session.description }}
          </h3>
        </div>
        <div class='flex flex-col gap-y-2'>
          <session-details-organizers
            v-for='(organizersByRole, role) in organizersGroupedByRole'
            :key='`${session.id}-organizer-role-${role}`'
            :organizers-by-role='organizersByRole'
            :role='role'
            :session-id='session.id'
            class='border-t pt-3 border-gray-100' />
        </div>
      </div>
    </div>
    <div class='flex flex-row justify-start items-center gap-x-2 mb-2'>
      <button v-if='showTopicDetailsToggleButton'
        class='rounded-md py-2 px-8 uppercase font-semibold text-sm w-auto border whitespace-nowrap'
        @click='toggleShowingTopics'>
        {{showTopicsButtonText}}
      </button>
      <button v-if='showGotoQnaButton && hasValidToken'
        class='rounded-md py-2 px-12 uppercase text-white font-semibold text-sm w-full lg:w-auto'
        :style='eventMainThemeColorBg'
        @click='goToQnA'>
        go to q&a
      </button>
    </div>
    <div class='border-l border-r border-b' :class='sessionTopicDetailsStyle'>
      <session-details-by-topic 
        v-for='(subSession, index) in sessionSpeakersByTopic'
        :key='`subSession-${index}`'
        class='border-t'
        :subSession='subSession'
        :highlight-string='highlightString'
        :session-index='index' />
    </div>
  </div>
</template>

<script>
import { HeartIcon as HeartOutline } from '@vue-hero-icons/outline'
import { HeartIcon as HeartSolid } from '@vue-hero-icons/solid'
import { CaretDownIcon, CaretRightIcon } from 'vue-tabler-icons'
import { mapState, mapGetters, mapActions } from 'vuex'
import DateTimeStringHelpers    from '@/utils/date-time-string-helpers'
import StringHelpers            from '@/utils/string-helpers'
import groupBy                  from 'lodash/groupBy' 
import SessionDetailsOrganizers from '@/components/icola2023/SessionDetailsOrganizers.vue'
import SessionDetailsByTopic    from '@/components/icola2023/SessionDetailsByTopic.vue'

export default {
  name: 'SessionDetailsFull',
  components: {
    SessionDetailsOrganizers,
    SessionDetailsByTopic,
    HeartOutline,
    HeartSolid,
    CaretDownIcon,
    CaretRightIcon,
  },
  props: {
    'session': {
      type: Object,
      required: true,
    },
    highlightString: {
      type: String,
      default: '',
    },
    repeatedComponent: {
      type: Boolean,
      default: false,
    },
    showTopicDetailsToggleButton: {
      type: Boolean,
      default: false,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
    sessionIndex: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      showTopics: !this.showTopicDetailsToggleButton,
      showDetails: false,
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventProgramsScheduleConfigurations',
      'eventMainThemeColorBg',
      'dateLocaleString',
    ]),
    sessionScheduledStartDate () {
      return DateTimeStringHelpers.toDateWithDay(this.session.scheduledStartTime, this.dateLocaleString)
    },
    sessionScheduledStartTime () {
      return DateTimeStringHelpers.toTime(this.session.scheduledStartTime)
    },
    sessionScheduledEndTime () {
      return DateTimeStringHelpers.toTime(this.session.scheduledEndTime)
    },
    sessionLocationString () {
      return this.session.locations.map(location => location.split('||')[0]).join(', ')
    },
    sessionSpeakersByTopic () {
      return this.showTopics ? this.session.listOfSpeakers : []
    },
    sessionTopicDetailsStyle () {
      return this.repeatedComponent ? 'border-b' : 'border-b'
    },
    showTopicsButtonText () {
      return this.showTopics ? 'Hide Topics' : 'Show Topics'
    },
    organizersGroupedByRole () {
      return (this.hasSessionConfigurationsOrganizers) ? groupBy(this.session.configurations.organizers, 'role') : []
    },
    hasSessionConfigurationsOrganizers () {
      return this.session.configurations.organizers && this.session.configurations.organizers.length > 0
    },
    showHeartButton () {
      return this.eventProgramsScheduleConfigurations.showHeartButton || false
    },
    showGotoQnaButton () {
      return this.session.configurations && this.session.configurations.showSessionDetailsQnaButton
    },
    heartedStatusIconStyle () {
      return (this.session.me && this.session.me.myLikeStatus) ? `color: ${this.sessionSlotBorderColor}` : 'color: #AAA'
    },
    whichHeartIcon () {
      return (this.session.me && this.session.me.myLikeStatus) ? 'heart-solid' : 'heart-outline'
    },
    whichDetailsCarot () {
      return (this.showDetails) ? 'caret-down-icon' : 'caret-right-icon'
    },
    toggleDetailsText () {
      return (this.showDetails) ? 'hide details' : 'show details'
    },
    backgroundImage () {
      let offset = this.sessionIndex % 10
      let horizontal = offset * 10
      let vertical  = (offset * 4) 
      return `background:url(https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/icola_main_opacity20.png);background-repeat: no-repeat; background-position: ${horizontal}% -${vertical}%;`
    },
    textSizeBasedOnLocation() {
      return (this.inModal) ? 'text-xl lg:text-2xl gap-x-0' : 'text-xl lg:text-3xl'
    },
  },
  methods: {
    ...mapActions('sessions', [
      'postLikeSession',
    ]),
    toggleShowingTopics () {
      this.showTopics = !this.showTopics
    },
    goToQnA () {
      this.$modal.hide('details-modal')
      this.$router.push({ name: 'Qna', query: { session_id: this.session.id }})
    },
    toggleHearted () {
      this.postLikeSession(this.session.id).then(resp => {
        if (resp.likedStatus == 'active') { // just turn on locally. Will get right data from api
          this.session.me.myLikeStatus = true
        } else {
          this.session.me.myLikeStatus = false
        }
      })
    },
    toggleShowDetails () {
      this.showDetails = !this.showDetails
    },
    highlightedText (text) {
      if (this.highlightString) {
        return StringHelpers.highlightString(text, this.highlightString)
      } else {
        return text
      }
    },
  }
}
</script>

