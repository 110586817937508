<template>
  <div class='flex flex-row justify-start gap-x-4 text-xs lg:text-base'>
    <div class='font-medium uppercase text-gray-600 w-24 lg:w-36'>
      {{role}}
    </div>
    <div class='flex flex-row gap-y-2 gap-x-4'>
      <div v-for='(organizer, index) in organizersByRole'
        :key='`${sessionId}-organizer-${organizer.name}-${index}`'
        class='flex flex-row justify-start items-center gap-x-1'>
        <div class='font-medium'>{{organizer.name}}</div>
        <div class='text-gray-600 whitespace-pre-line'>({{organizer.affiliation}})</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SessionDetailsOrganizers',
  props: [
    'organizersByRole',
    'role',
    'sessionId',
  ],
}
</script>

