<template>
  <div class='flex lg:flex-row flex-wrap gap-y-1 lg:gap-x-1 lg:my-1'>
    <div 
      class='w-full flex flex-col lg:flex-row items-start lg:items-center gap-x-4 lg:gap-x-2 lg:p-2 gap-y-3'
      :class='speakerDetailsClickable(firstSpeaker)'
      @click.stop='seeSpeakerDetails(firstSpeaker)'>
      <div class='px-2 pt-4 lg:pt-0 lg:px-4 text-base font-medium flex flex-col justify-start items-start lg:block gap-y-1'>
        <span class='whitespace-nowrap inline-block leading-4 text-sm'>{{ subSession.topicTimeText }}</span>
        <span class='inline-block lg:hidden text-base lg:text-lg font-semibold text-gray-800 leading-4' 
          v-html='highlightedText(subSession.topic)'></span>
      </div>
      <div class='flex flex-row justify-start gap-x-2 lg:gap-x-4 bg-gray-50 p-2 lg:bg-transparent w-full'>
        <img 
          :class='hideSpeakeImageIfNotAvailable(firstSpeaker)'
          :src='firstSpeaker.imageUrl' 
          class='lg:mx-0 object-cover flex- shrink-0 lg:rounded-md' 
          style='object-position:50% 10%;'>
        <div class='text-left my-auto lg:ml-2 lg:mr-0 text-gray-700'>
          <div class='flex flex-row justify-start items-center gap-x-2'>
            <div 
              class='hidden lg:block text-base lg:text-lg font-semibold text-gray-800' 
              v-html='highlightedText(subSession.topic)'></div>
            <a v-if='presentationMaterialUrl' 
              :href='presentationMaterialUrl'
              target='_blank'
              :style='themeTextColorStyle'
              class='text-xs font-normal border pl-1 pr-2 py-1 rounded flex flex-row justify-center items-center uppercase'>
              <cloud-download-icon class='h-3'/> 
              Presentation
            </a>
          </div>
          <div class='text-sm lg:text-base font-medium' v-html='highlightedText(firstSpeaker.name)'></div>
          <div class='text-xs whitespace-pre-line'>
            {{firstSpeaker.affiliation}}{{speakerCountryString(firstSpeaker)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CloudDownloadIcon } from 'vue-tabler-icons'
import StringHelpers from '@/utils/string-helpers'

export default {
  name: 'SessionDetailsByTopic',
  components: {
    CloudDownloadIcon,
  },
  props: {
    subSession: {
      type: Object,
      required: true,
    },
    highlightString: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
    presentationMaterialUrl () {
      let found = this.subSession.speakers.find(speaker => speaker.presentationMaterialUrl !== '')
      return (found) ? found.presentationMaterialUrl : ''
    },
    firstSpeaker () {
      return this.subSession.speakers[0]
    },
  },
  methods: {
    ...mapActions([
      'openSpeakerDetailsModal',
    ]),
    hideSpeakeImageIfNotAvailable (speaker) {
      return (speaker.imageUrl) ? 'w-24 h-24' : 'hidden'
    },
    highlightedText (text) {
      if (this.highlightString) {
        return StringHelpers.highlightString(text, this.highlightString)
      } else {
        return text
      }
    },
    speakerCountryString (speaker) {
      return speaker.country ? `, ${speaker.country}` : ''
    },
    speakerContentSpeakerConfigurations (speaker) {
      return speaker.contentSpeakerConfigurations ? speaker.contentSpeakerConfigurations : {}
    },
    seeSpeakerDetails (speaker) {
      if (this.speakerContentSpeakerConfigurations(speaker).hideSpeakerDetails) return
      this.openSpeakerDetailsModal(speaker.id)
    },
    speakerDetailsClickable (speaker) {
      return (this.speakerContentSpeakerConfigurations(speaker).hideSpeakerDetails) ? '' : 'hover:shadow-md hover:rounded-xl standard-transition cursor-pointer'
    },
  },
}
</script>